// import './plugins/composition-api'
import './plugins/auth'
import './plugins/agentHub'
import '@/booking/useBookingStatus'
import '@/plugins/axiosPlugin'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
