import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGaurd } from './routeGaurds'
import { useAuth } from '@/plugins/auth'

import Home from '../views/Home.vue'
import SignIn from '@/views/Login.vue'
import TripBrowser from '../views/TripBrowser.vue'
import TicketList from '@/views/ticket/TicketList'
import TicketPrint from '@/views/ticket/TicketPrint'
const FourOFour = () => import(/* webpackChunkName: "FourOFour" */ "@/views/Error.vue");
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ "@/views/ForgotPassword.vue");

Vue.use(VueRouter)

const { isAuthenticated, subscribeToAuthenticationEvents } = useAuth()

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: SignIn,
    meta: { requiresSignout: true, layout: 'other' }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { requiresSignout: true, layout: 'other' }
  },
  {
    path: '/trips',
    name: 'Trips',
    component: TripBrowser,
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets',
    name: 'TicketList',
    component: TicketList,
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets/:pnr',
    name: 'TicketPrint',
    component: TicketPrint,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: '404',
    component: FourOFour,
    meta: { layout: isAuthenticated.value ? undefined : 'other' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(authGaurd)

import appRoutes from './useAppRoutes'
subscribeToAuthenticationEvents(redirectOnAuthEvents)
function redirectOnAuthEvents(user) {
  if (user) {
    if (!router.history.pending?.name) {
      router.replace(appRoutes.homeRoute)
    }
  } else {
    router.replace(appRoutes.signinRoute)
  }
}

export default router
export { default as useRouteWatchers } from './useRouteWatchers'
export { useRoute } from './useRouter'