<template>
  <div v-if="loading"></div>
  <v-row v-else dense align="center" class="pa-2 white elevation-7 rounded-lg">
    <v-col cols="12" md="6" lg="4">
      <v-card height="40" color="transparent">
        <v-select
          v-model="tripCriteria.origin"
          :items="sites"
          item-text="name"
          item-value="id"
          return-object
          label="من"
          outlined
          dense
        >
          <template v-slot:append>
            <div class="mt-n1">
              <v-chip v-if="tripCriteria.origin">{{
                tripCriteria.origin.cityName
              }}</v-chip>
              <v-icon>mdi-menu-down</v-icon>
            </div>
          </template>
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <!-- <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <span>{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <v-chip text-color="pink" small>{{
                      item.cityName
                    }}</v-chip>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <v-card height="40" color="transparent">
        <v-select
          v-model="tripCriteria.destination"
          :items="sites"
          item-text="name"
          item-value="id"
          return-object
          label="إلى"
          outlined
          dense
        >
          <template v-slot:append>
            <div class="mt-n1">
              <v-chip v-if="tripCriteria.destination">{{
                tripCriteria.destination.cityName
              }}</v-chip>
              <v-icon>mdi-menu-down</v-icon>
            </div>
          </template>
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <!-- <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <span>{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <v-chip text-color="pink" small>{{
                      item.cityName
                    }}</v-chip>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-card>
    </v-col>
    <v-col cols="12" md="8" lg="2">
      <v-card height="40" color="transparent">
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tripCriteria.date"
              label="تاريخ السفر"
              append-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="tripCriteria.date"
            :min="minDate"
            @input="showMenu = false"
          />
        </v-menu>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" lg="2">
      <v-btn
        color="pink"
        class="white--text"
        @click="invokeSearch"
        width="100%"
      >
        ابحث
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import useSites from "@/stationSites";
import useTripSearchCriteria from "@/store/useTripBrowseCriteria";

export default {
  setup() {
    const { sitesGroupedByCity: sites, loading } = useSites();
    const date = new Date();
    const minDate = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,0)}-${(date.getDate().toString().padStart(2,0))}`;
    const { tripCriteria, invokeSearch } = useTripSearchCriteria();

    return {
      sites,
      loading,
      minDate,
      tripCriteria,
      invokeSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
