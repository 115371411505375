const routes = {
  homeRoute: stringToRouteObject('Home'),
  notFoundRoute: stringToRouteObject('404'),
  signinRoute: stringToRouteObject('Signin'),
  forgotPasswordRoute: stringToRouteObject('forgot-password'),
  ticketPrintRoute: (pnr) => ({
    name: 'TicketPrint', params: { pnr: pnr }
  }),
}
export default routes

function stringToRouteObject(name) {
  return { name: name }
}