<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "@/router";

const defaultLayout = "default";

export default {
  name: "AppLayout",
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      const x = route.value.meta.layout || defaultLayout;
      return () => import(`./${x}/index.vue`);
    });
    return { layout };
  },
};
</script>

<style>
</style>