import { computed, getCurrentInstance } from 'vue'

export function useRoute() {
  const instance = getCurrentInstance();

  return computed(() => instance.proxy.$route);
}
export function useRouter() {
  const { proxy } = getCurrentInstance()
  const router = proxy.$router
  return router
}