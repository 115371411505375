import { watch, watchEffect } from "vue";
import { useRoute } from "@/router/useRouter";

export default function () {
  const route = useRoute()
  function watchRouteQuery(hook, immediate = false) {
    // watch(() => route.value.query, (newValue) => { console.log('useRouteWatchers.watchRouteQuery', newValue); hook() }, { immediate })
    watch(() => route.value.query, hook, { immediate })
  }
  function watchRouteParams(hook) {
    return watchEffect(() => {
      hook(route.value.params)
    })
  }
  function watchRouteMeta(hook, immediate = false) {
    // watch(() => route.value.meta, (newValue) => { console.log('useRouteWatchers.watchRouteMeta', newValue); hook() }, { immediate })
    watch(() => route.value.meta, hook, { immediate })
  }
  return { watchRouteQuery, watchRouteParams, watchRouteMeta }
}
