<template>
  <div>
    <v-card
      tag="li"
      hover
      height="80"
      class="d-flex align-center px-5"
      :class="trip.expand && 'active-focused'"
    >
      <div
        class="d-flex align-center"
        :class="$vuetify.breakpoint.xs && 'flex-column-reverse text-center'"
      >
        <h3 class="text-sm-h4 me-sm-4">{{ trip.tripDepartureDisplayDate }}</h3>
        <i :style="false && 'visibility: hidden;'" style="width: 130px"
          ><v-chip input-value="true">{{
            trip.tripClassDisplayName
          }}</v-chip></i
        >
      </div>
      <v-spacer />
      <div
        class="d-flex align-center"
        :class="$vuetify.breakpoint.xs && 'flex-column'"
      >
        <em style="font-style: normal" class="text-sm-h6 me-sm-6">{{
          trip.ticketPrice + " جنيه"
        }}</em>
        <v-btn
          v-if="!trip.expand"
          color="pink darken-1"
          small
          rounded
          class="font-weight-bold white--text"
          @click.stop="OpenCloseTripHanlder(trip)"
        >
          اختار المقاعد
        </v-btn>
        <v-btn
          v-else
          color="pink darken-1"
          class="white--text"
          small
          rounded
          @click.stop="OpenCloseTripHanlder(trip)"
        >
          اغلاق
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>
    <BookingPanel v-if="trip.expand" :trip="trip" height="120"></BookingPanel>
  </div>
</template>

<script>
const BookingPanel = () =>
  import(
    /* webpackChunkName: "BookingPanel" */ "@/components/TripCardBookingPanel.vue"
  );
export default {
  components: { BookingPanel },
  props: {
    trip: { type: Object, required: true },
    OpenCloseTripHanlder: { type: Function, require: true },
    i: { type: Number, required: true },
  },
  setup() {
    return { BookingPanel };
  },
};
</script>

<style lang="scss" scoped>
.active-focused {
  background: #e8f5fa;
}
</style>