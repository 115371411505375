export function dateToTwentyFourTimeString(date, locale = 'ar-EG', fallbackLocale = 'en-US') {
  return !isValidDate(date) ? '' : date.toLocaleTimeString([locale, fallbackLocale], {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  })
}
export function dateToAmOrPmLocaleAware(date, locale = 'ar-EG', fallbackLocale = 'en-US') {
  return !isValidDate(date) ? '' : date.toLocaleTimeString([locale, fallbackLocale], {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  }).split(' ')[1]
}
export function dateToAmOrPm(date) {
  return !isValidDate(date) ? '' : date.getHours() < 12 ? 'صباحًا' :
    'مساءً'
}
export function dateToWeekDayName(date, locale = 'ar-EG', fallbackLocale = 'en-US') {
  return !isValidDate(date) ? '' : date.toLocaleDateString([locale, fallbackLocale], {
    weekday: "long",
  });
}
export function dateToLocaleDateString(date, locale = 'ar-EG', fallbackLocale = 'en-US') {
  return !isValidDate(date) ? '' : date.toLocaleDateString([locale, fallbackLocale], {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
export function hoursToTimeSpan(hoursTimeSpan) {
  const minsTimeSpan = hoursTimeSpan * 60
  return {
    hours: Math.trunc(minsTimeSpan / 60),
    minutes: Math.trunc(minsTimeSpan % 60)
  }
}
export function minutesToTimeSpan(minutesTimeSpan) {
  return {
    hours: Math.trunc(minutesTimeSpan / 60),
    minutes: Math.trunc(minutesTimeSpan % 60)
  }
}
export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}