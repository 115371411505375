import { readonly, ref } from 'vue'
import useSites from '@xbus/cities-and-sites'

const { loading, getSites } = useSites()
const sites = ref(null)
const sitesGroupedByCity = ref(null)

export default function () {
  return {
    loading,
    sites: readonly(sites),
    getSiteById: (id) => sites.value.find(s => s.id === id),
    sitesGroupedByCity: readonly(sitesGroupedByCity),
    loadSites
  }
}
async function loadSites() {
  const siteData = await getSites()
  //sort by city id
  // siteData.sort((a, b) => a.cityId < b.cityId ? -1 : a.cityId === b.cityId ? 0 : 1)
  sitesGroupedByCity.value = groupSitesByCity([...siteData])
  sites.value = siteData
}
function groupSitesByCity(sites) {
  let site
  for (let i = 0; i < sites.length; i++) {
    if (!sites.some((s) => s.separatorCityId === sites[i].cityId)) {
      site = sites[i]
      sites.splice(i, 0, { separatorCityId: site.cityId, divider: true });
      // sites.splice(i + 1, 0, { header: site.cityName, separatorCityId: site.cityId });
    }
  }
  sites.shift()
  return sites;
}