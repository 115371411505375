import { computed, readonly, ref, watch } from "vue";
import { getAvailableCredit } from './agentCreditApi'
import hub from '@/plugins/agentHub'

const credit = ref('!')
const loading = ref(false)

export default function useAgentCredit() {
  return {
    credit: computed(() => credit.value),
    GetAgentCredit,
    loading: readonly(loading),
    cleanup: hub.cleanup
  }
}

hub.onConnecting(() => setCreditAmount('جاري الاتصال'))
hub.onConnected(async () => {
  hub.onReconnecting(() => setCreditAmount('جاري معاودة الاتصال'))
  hub.onReconnected(async () => await GetAgentCredit())
  hub.joinChannel('agentCreditUpdated', ({ availableAmount }) => setCreditAmount(availableAmount))
  await GetAgentCredit()
})
watch(hub.connectionStatus, (newState) => {
  switch (newState) {
    case 'uninitialized':
    case 'disconnected':
      setCreditAmount('!')
      break;
  }
}, { immediate: true })


async function GetAgentCredit() {
  loading.value = true
  try {
    const { data } = await getAvailableCredit()
    setCreditAmount(data)
  } finally {
    loading.value = false
  }
}

function setCreditAmount(amount) {
  credit.value = amount
}
