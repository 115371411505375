<template>
  <div></div>
</template>

<script>
// import { useAuth } from "@xbus/auth";

export default {
  // setup() {
  //   const { currentUser } = useAuth();
  //   const expiryDate = new Date(currentUser.value.exp);
  //   return {
  //     currentUser,
  //     expiryDate,
  //   };
  // },
};
</script>
