<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="tableHeaders"
      :items="tickets"
      :page.sync="pageIndex"
      :items-per-page.sync="pageSize"
      class="elevation-1"
      @page-count="pageCount = $event"
      :server-items-length="ticketsTotalCount"
      :footer-props="{
        'items-per-page-options': $vuetify.breakpoint.xs
          ? [3, 6, 10]
          : [10, 20, 30],
        'items-per-page-text': 'التذاكر المعروضة في كل صفحة',
      }"
      disable-sort
      item-key="ticketId"
    >
      <template v-slot:item.actions="{ item }">
        <v-speed-dial
          v-model="item.fab"
          direction="right"
          :open-on-hover="false"
          transition="slide-x-transition"
          v-if="item.status !== 'Cancelled'"
        >
          <!-- transition="slide-x-reverse-transition" -->
          <template v-slot:activator>
            <v-btn v-model="item.fab" small text fab>
              <v-icon v-if="item.fab">mdi-close</v-icon>
              <v-icon v-else>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            x-small
            color="error"
            @click="cancelThisTicket(item)"
            :disabled="item.status === 'Cancelled'"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            x-small
            color="green"
            :to="{ name: 'TicketPrint', params: { pnr: item.pnr } }"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-speed-dial>
      </template>
      <template v-slot:item.status="{ item }">
        <!-- <v-chip :color="ticketStatusIcon(item.status)" dark class="text-subtitle-1"> {{ item.status }}</v-chip> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="ticketStatusIcon(item.status).color"
              v-bind="attrs"
              v-on="on"
              >{{ ticketStatusIcon(item.status).icon }}</v-icon
            >
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center pt-2" v-if="false">
      <v-pagination v-model="pageIndex" :length="pageCount"></v-pagination>
      <v-text-field
        label="Items per page"
        type="number"
        min="-1"
        max="15"
        v-model="pageSize"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import useBooking from "@/booking/useBooking";

export default {
  setup() {
    const pageSize = ref(10);
    const pageIndex = ref(1);
    const pageCount = ref(0);
    const ticketsTotalCount = ref(0);
    const tickets = ref([]);
    const tableHeaders = [
      { text: "تاريخ الحجز", value: "bookingDate" },
      { text: "رقم التذكرة", value: "pnr" },
      { text: "رقم الرحلة", value: "routeDisplayName" },
      { text: "الحالة", value: "status" },
      { text: "", value: "actions", sortable: false },
    ];

    const {
      getAgentTickets,
      cancelTicket,
      loading,
      getAgentTicketsTotalCount,
    } = useBooking();
    async function loadTickets() {
      try {
        const ticketData = await getAgentTickets(
          pageIndex.value,
          pageSize.value
        );
        ticketData.forEach((t) => {
          t.departureDate = t.departureDate.toLocaleString();
          t.bookingDate = t.bookingDate.toLocaleString();
        });
        ticketData.forEach((t) => {
          t.fab = false;
        });
        tickets.value = ticketData;
      } catch (error) {
        console.log(error.message, error.response.data);
      }
    }
    watch(
      [pageIndex, pageSize],
      async ([page, size]) => {
        try {
          await loadTickets(page, size);
        } catch {}
      },
      { immediate: true }
    );
    onBeforeMount(async () => {
      try {
        ticketsTotalCount.value = await getAgentTicketsTotalCount();
      } catch {}
    });

    const ticketStatusIcon = computed(() => (ticketStatus) => {
      if (ticketStatus === "Cancelled")
        return { color: "red lighten-2", icon: "mdi-cancel" };
      else if (ticketStatus === "Pending")
        return { color: "orange", icon: "mdi-progress-question" };
      else if (ticketStatus === "Provisional")
        return { color: "orange lighten-2", icon: "mdi-progress-check" };

      //ticketStatus === "Final"
      return { color: "green lighten-2", icon: "mdi-check-outline" };
    });

    async function cancelThisTicket(ticket) {
      try {
        await cancelTicket(ticket.pnr);
        ticket.status = "Cancelled";
      } catch (error) {
        console.log(error);
      }
    }
    return {
      loading,
      tickets,
      tableHeaders,
      ticketStatusIcon,
      cancelThisTicket,
      pageIndex,
      pageCount,
      pageSize,
      ticketsTotalCount,
    };
  },
};
</script>

<style>
</style>

