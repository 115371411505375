import { reactive } from "vue";

const date = new Date();
const tripCriteria = reactive({
  date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}-${(date.getDate().toString().padStart(2, 0))}`,
  origin: null,
  destination: null,
});

export default function () {
  return {
    tripCriteria,
    invokeSearch,
    listenToInvokeSearch,
  }
}
const listenersOfInvokeSearch = new Set()

function listenToInvokeSearch(callback) {
  listenersOfInvokeSearch.add(callback)
  return () => listenersOfInvokeSearch.delete(callback)
}
function invokeSearch() {
  listenersOfInvokeSearch.forEach(callback => callback(tripCriteria))
}
// function updateCriteria({ date, origin, destination }) {
//   if (date) {
//     tripCriteria.date = date
//   }
//   if (origin) {
//     tripCriteria.origin = origin
//   }
//   if (destination) {
//     tripCriteria.destination = destination
//   }
// }