import appRoutes from './useAppRoutes'
import { useAuth } from '@/plugins/auth'

const { isAuthenticated, waitForAuthInitialization: waitOnAuth } = useAuth()

export async function authGaurd(to, from, next) {
  await waitOnAuth()
  //routes that require authenticated user to be present
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (isAuthenticated.value) {
      next()
    } else {
      next(appRoutes.signinRoute)
    }
    //routes that require no authenticated user.
  } else if (to.matched.some(route => route.meta.requiresSignout)) {
    if (!isAuthenticated.value) {
      next()
    } else {
      next(false)
    }
  } else next()
}