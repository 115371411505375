<template>
  <div class="pt-2">
    <div class="d-flex justify-center" id="print-me">
      <div class="rounded-lg white elevation-7 pa-3">
        <TicketPrintCard v-if="ticket" :ticket="ticket" />
      </div>
    </div>
    <!-- <v-btn @click="print">print</v-btn> -->
  </div>
</template>

<script>
import TicketPrintCard from "@/components/TicketPrintCard.vue";
import { ref } from "vue";
import useBooking from "@/booking/useBooking";
import { useRouteWatchers } from "@/router";

export default {
  components: {
    TicketPrintCard,
  },
  props: ["pnr"],
  setup() {
    const { watchRouteParams } = useRouteWatchers();
    const { getTicketByPnr } = useBooking();

    const ticket = ref(null);
    
    watchRouteParams(async (params) => ticket.value = await getTicketByPnr(params.pnr))

    return { ticket };
  },
};
</script>

