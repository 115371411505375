<template>
  <strong>
    <div class="d-flex align-center grey lighten-3 px-5 py-3 text-h6">
      <v-img
        src="@/assets/bus-icon.png"
        class="me-5"
        :max-width="89"
        :max-height="32"
      />
      <div
        :class="$vuetify.breakpoint.smAndUp && 'd-flex align-center'"
        style="width: 100%"
      >
        <div>
          <div>من {{ originName }}</div>
          <div>إلى {{ destinationName }}</div>
        </div>
        <v-spacer></v-spacer>
        <div class="text-subtitle-1">
          <div class="mb-n3">{{ day }}</div>
          <div>{{ localeDate }}</div>
        </div>
      </div>
    </div>
  </strong>
</template>

<script>
import useTripSearchCriteria from "@/store/useTripBrowseCriteria";
import { dateToWeekDayName, dateToLocaleDateString } from "@/utils/dateAdapter";
import { ref } from "vue";
export default {
  setup() {
    const { listenToInvokeSearch } = useTripSearchCriteria();

    const day = ref("");
    const localeDate = ref("");
    const originName = ref("");
    const destinationName = ref("");

    listenToInvokeSearch(({ origin, destination, date }) => {
      const d = new Date(Date.parse(date));

      day.value = dateToWeekDayName(d);
      localeDate.value = dateToLocaleDateString(d);

      originName.value = origin?.name;
      destinationName.value = destination?.name;
    });

    return {
      day,
      localeDate,
      originName,
      destinationName,
    };
  },
};
</script>

<style>
</style>