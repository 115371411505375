import axios from '@/services/axios'
import appsettings from '@/appsettings'

export async function getTripsByDateAndRoute(date, origin, destination) {
  if (appsettings.apiDebugMessages) console.log('tripsApi.getTripsByDateAndRoute.request', { date, origin, destination })
  const response = await axios().get(`/trips/${date}/${origin}/${destination}`)
  if (appsettings.apiDebugMessages) console.log('tripsApi.getTripsByDateAndRoute.response', response)
  return response.data
}

export async function getTripSeatsByIdAndRoute(tripId, origin, destination) {
  if (appsettings.apiDebugMessages) console.log('tripsApi.getTripSeatsByIdAndRoute.request', { tripId, origin, destination })
  const response = await axios().get(`/seats/${tripId}/${origin}/${destination}`)
  if (appsettings.apiDebugMessages) console.log('tripsApi.getTripSeatsByIdAndRoute.response', response)
  return response.data
}

// export async function getTripsByDate(date) {
//   const response = await axios().get(`/trips/${date}`)
//   response.data.forEach((t) => {
//     t.tripDepartureDateTime = new Date(t.tripDepartureDateTime)
//     t.tripArrivalDateTime = new Date(t.tripArrivalDateTime)
//     // t.expand = false
//   })
//   return response.data
// } 