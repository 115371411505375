import hub from '@/plugins/agentHub'
import useToast from '@/useToast'
import routes from '@/router/useAppRoutes'

hub.onConnected(() => {
  hub.joinChannel('ticketBookingFailed', onBookingFailed)
  hub.joinChannel("ticketBooked", onTicketBooked)
  hub.joinChannel("ticketCancelled", onTicketCancelled)
})

const { ticketPrintRoute } = routes
const { error, inform } = useToast()

function onBookingFailed({ message }) {
  error(message)
}
function onTicketBooked({ pnr, ticketId }) {
  inform('تم حجز التذكرة  ', { text: pnr, to: ticketPrintRoute(pnr) });
}
function onTicketCancelled({ pnr }) {
  inform(`تم إلغاء التذكرة  ${pnr}`);
}