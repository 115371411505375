import { ref } from 'vue'
import * as api from './tripsApi'

export default function () {
  const loading = ref(false)

  async function getTripsByDateAndRoute(date, origin, destination) {
    loading.value = true
    try {
      return await api.getTripsByDateAndRoute(date, origin, destination)
    } finally {
      loading.value = false
    }
  }

  async function getTripSeatsByIdAndRoute(tripId, origin, destination) {
    loading.value = true
    try {
      const { data } = await api.getTripSeatsByIdAndRoute(tripId, origin, destination)
      return data
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    getTripsByDateAndRoute,
    getTripSeatsByIdAndRoute
  }
}

