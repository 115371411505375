import axios from '@/services/axios'
import appsettings from '@/appsettings'

export async function bookTicket({ tripId, origin, destination, seats, passengerName, passengerPhoneNumber }) {
  const bookTicketRequest = {
    tripId, originSiteId: origin, destinationSiteId: destination,
    seatNumbers: seats, passengerName, passengerPhoneNumber
  }
  if (appsettings.apiDebugMessages) console.log('bookingApi.bookTicket.request', bookTicketRequest)
  const response = await axios().post('/tickets', bookTicketRequest)
  if (appsettings.apiDebugMessages) console.log('bookingApi.bookTicket.response', response)
  return response.data
}

export async function getAgentTickets(pageIndex, pageSize) {
  if (appsettings.apiDebugMessages) console.log('bookingApi.getAgentTickets.request', `pageIndex=${pageIndex}&pageSize=${pageSize}`)
  const response = await axios().get(`/tickets?pageIndex=${pageIndex}&pageSize=${pageSize}`)
  if (appsettings.apiDebugMessages) console.log('bookingApi.getAgentTickets.response', response)
  return response.data
}
export async function getAgentTicketsTotalCount() {
  if (appsettings.apiDebugMessages) console.log('bookingApi.getAgentTicketsTotalCount.request')
  const response = await axios().get(`/tickets/GetAgentTicketsTotalCount`)
  if (appsettings.apiDebugMessages) console.log('bookingApi.getAgentTicketsTotalCount.response', response)
  return response.data
}
export async function getTicketByPnr(pnr) {
  if (appsettings.apiDebugMessages) console.log('bookingApi.getTicketByPnr.request', pnr)
  const response = await axios().get(`/tickets/${pnr}`)
  if (appsettings.apiDebugMessages) console.log('bookingApi.getTicketByPnr.response', response)
  return response.data
}

export async function cancelTicket(pnr) {
  if (appsettings.apiDebugMessages) console.log('bookingApi.cancelTicket.request', pnr)
  const response = await axios().delete(`/tickets/${pnr}`)
  if (appsettings.apiDebugMessages) console.log('bookingApi.cancelTicket.response', response)
  return response.data
}