<template>
  <Signin :forgotPasswordRoute="forgotPasswordRoute" @failed="failed" />
</template>

<script>
import { Signin } from "@xbus/auth-ui";
import useAppRoutes from "@/router/useAppRoutes";

export default {
  components: { Signin },
  setup() {
    const { forgotPasswordRoute } = useAppRoutes;
    async function failed(err) {
      // if (err.arabic) {
      //   await error(err.arabic);
      // } else {
      //   console.error(err);
      // }
    }
    return {
      forgotPasswordRoute,
      failed,
    };
  },
};
</script>

<style>
</style>