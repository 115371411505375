<template>
  <div>
    <div class="pa-sm-3 pa-1 mb-4">
      <TripSearch />
    </div>
    <div class="pa-sm-3 pa-0" style="position: relative">
      <TripListHeader />
      <TripCard
        v-for="(trip, index) in trips"
        :key="trip.tripId"
        class="mb-1"
        :trip="trip"
        :i="index"
        :OpenCloseTripHanlder="OpenCloseTrip"
      />
      <v-overlay :absolute="true" :value="loadingTrips">
        <v-progress-circular :size="75" color="primary" indeterminate />
      </v-overlay>
      <v-row v-if="noTripsFound" align="center" justify="center" class="my-5">
        <span class="text-h6 sideBar--text">لم نجد أي رحلات :\</span>
      </v-row>
    </div>
  </div>
</template>

<script>
import TripSearch from "@/components/TripSearch.vue";
import TripCard from "@/components/TripCard.vue";
import TripListHeader from "@/components/TripListHeader.vue";
import { onBeforeMount, onUnmounted, ref } from "vue";
import useTrips from "@/trips";
import useSites from "@/stationSites";
import { dateToTwentyFourTimeString, dateToAmOrPm } from "@/utils/dateAdapter";
import useTripSearchCriteria from "@/store/useTripBrowseCriteria";

export default {
  components: { TripSearch, TripCard, TripListHeader },
  setup() {
    const { loadSites } = useSites();
    onBeforeMount(async () => await loadSites());

    const trips = ref([]);
    const noTripsFound = ref(false);
    const { loading: loadingTrips, getTripsByDateAndRoute } = useTrips();

    async function loadTrips({ date, origin, destination }) {
      if (origin && destination && date) {
        try {
          const tripsData = await getTripsByDateAndRoute(
            date,
            origin.id,
            destination.id
          );
          tripsData.forEach((t) => {
            t.tripDepartureDateTime = new Date(t.tripDepartureDateTime);
            t.tripArrivalDateTime = new Date(t.tripArrivalDateTime);
            t.tripDepartureDisplayDate =
              dateToTwentyFourTimeString(t.tripDepartureDateTime) +
              " " +
              dateToAmOrPm(t.tripDepartureDateTime);
            t.expand = false;
          });
          noTripsFound.value = tripsData.length === 0;
          trips.value = tripsData;
        } catch (error) {
          console.log(error.message, error.response?.data);
        }
      }
    }
    const { listenToInvokeSearch } = useTripSearchCriteria();
    const stopListening = listenToInvokeSearch(loadTrips);
    onUnmounted(stopListening);

    let currentOpenedTrip = null;
    function OpenCloseTrip(trip) {
      if (trip.expand) {
        //opened, close it.
        currentOpenedTrip.expand = false;
        currentOpenedTrip = null;
      } else {
        //closed, open it. after closing currently opened if any
        if (currentOpenedTrip) {
          currentOpenedTrip.expand = false;
        }
        currentOpenedTrip = trip;
        currentOpenedTrip.expand = true;
      }
    }
    return {
      trips,
      noTripsFound,
      loadingTrips,
      OpenCloseTrip,
    };
  },
};
</script>