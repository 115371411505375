<template>
  <v-app>
    <!-- clipped-right -->
    <div v-if="initializing">
      <LoadingComponent />
    </div>
    <div v-else>
      <AppLayout>
        <router-view />
      </AppLayout>
    </div>
    <Toast />
  </v-app>
</template>

<script>
import { useAuth, errorCodeToArabMsg } from "@/plugins/auth";
import { useToast, Toast } from "@xbus/toast";
import LoadingComponent from "./components/LoadingComponent.vue";
import AppLayout from "@/components/layouts/AppLayout.vue";
import { onBeforeUnmount } from "vue";
import useAgentCredit from "./agentCredit";

export default {
  components: { LoadingComponent, AppLayout, Toast },
  name: "App",
  setup() {
    const { toast, error } = useToast();
    const {
      cleanup: authCleanup,
      initializing,
      subscribeToAuthError,
    } = useAuth();

    let arbErrMsg;
    subscribeToAuthError((err) => {
      if (err.code) {
        arbErrMsg = errorCodeToArabMsg(err.code);
        if (arbErrMsg) {
          error(arbErrMsg);
        }
      } else {
        console.error(err);
      }
    });

    const { cleanup: agentCreditCleanup } = useAgentCredit();
    onBeforeUnmount(() => {
      authCleanup();
      agentCreditCleanup();
    });
    return {
      initializing,
      toast,
    };
  },
};
</script>

<style lang="scss">
// @import '@/scss/variables.scss';
.nav-drawer {
  background-color: #2c333e !important;
}
</style>
