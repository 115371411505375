import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ar from 'vuetify/lib/locale/ar';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { ar },
    current: 'ar',
  },
  icons: { iconfont: 'mdi' },
  theme: {
    themes: {
      light: {
        primary: '#ee44aa',
        // primary: '#F0F2F9',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        sideText: '#B0BFB1',
        body: '#F9FBFD',
        sideBar: '#283447'
      },
    },
  },
});
