var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._m(0),_c('div',[_c('v-img',{attrs:{"src":"","contain":"","height":"100","width":"115"}})],1)]),_c('v-row',[_c('v-col',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)],1),_c('v-col',[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)],1)],1),_c('div',{staticClass:"d-flex mt-n3"},[_c('v-spacer'),_c('div',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.ticket.pnr))])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-h4"},[_vm._v(" ستارلاين "),_c('i',{staticClass:"text-caption me-1"},[_vm._v("مصر")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"تاريخ السفر","value":_vm.ticket.departureDate.toLocaleDateString()}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"محطة القيام","value":_vm.ticket.origin}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"أرقام المقاعد","value":_vm.ticket.seatNumbers}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"الاسم","value":_vm.ticket.consumerName}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"وقت القيام","value":_vm.ticket.departureDate.toLocaleTimeString()}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"محطة الوصول","value":_vm.ticket.destination}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"السعر الإجمالي","value":_vm.ticket.totalFare}})
},function (){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:_vm.textFieldStyle,attrs:{"dense":"","outlined":"","readonly":"","label":"التليفون","value":_vm.ticket.phoneNumber}})
}]

export { render, staticRenderFns }