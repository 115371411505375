import { computed, ref } from 'vue'
import * as api from './bookingApi'

const loading = ref(false)
export default function useBooking() {

  async function bookTicket({ tripId, origin, destination, seats, passengerName, phoneNumber }) {
    loading.value = true
    try {
      await api.bookTicket({ tripId, origin, destination, seats, passengerName, passengerPhoneNumber: phoneNumber })
    } finally {
      loading.value = false
    }
  }

  async function getAgentTickets(pageIndex, pageSize) {
    loading.value = true
    try {
      const tickets = await api.getAgentTickets(pageIndex, pageSize)
      tickets.forEach(t => {
        t.departureDate = new Date(t.departureDate)
        t.bookingDate = new Date(t.bookingDate)
      });
      return tickets
    } finally {
      loading.value = false
    }
  }
  async function getAgentTicketsTotalCount() {
    loading.value = true
    try {
      const ticketsTotalCount = await api.getAgentTicketsTotalCount();
      return ticketsTotalCount
    } finally {
      loading.value = false
    }
  }

  async function getTicketByPnr(pnr) {
    loading.value = true
    try {
      const ticket = await api.getTicketByPnr(pnr)
      ticket.departureDate = new Date(ticket.departureDate)
      ticket.bookingDate = new Date(ticket.bookingDate)
      return ticket
    } finally {
      loading.value = false
    }
  }

  async function cancelTicket(pnr) {
    loading.value = true
    try {
      await api.cancelTicket(pnr)
    } finally {
      loading.value = false
    }
  }

  return {
    loading: computed(() => loading.value),
    bookTicket, getAgentTickets,
    getTicketByPnr, cancelTicket,
    getAgentTicketsTotalCount
  }
}