<template>
  <div class="ticket">
    <div class="d-flex align-center justify-space-between">
      <div class="text-h4">
        ستارلاين
        <i class="text-caption me-1">مصر</i>
      </div>
      <div>
        <v-img src="" contain height="100" width="115"/>
      </div>
    </div>
    <!-- <div class="text-h6 text-center pb-2">مذكرة سفر</div> -->
    <v-row>
      <v-col>
        <v-text-field
          dense outlined v-once readonly
          label="تاريخ السفر"
          :class="textFieldStyle"
          :value="ticket.departureDate.toLocaleDateString()" />
        <v-text-field
          dense outlined v-once readonly
          label="محطة القيام"
          :class="textFieldStyle"
          :value="ticket.origin" />
        <v-text-field
          dense outlined v-once readonly
          label="أرقام المقاعد"
          :class="textFieldStyle"
          :value="ticket.seatNumbers" />
        <v-text-field
          dense outlined v-once readonly
          label="الاسم"
          :class="textFieldStyle"
          :value="ticket.consumerName" />

      </v-col>
      <v-col>
        <v-text-field
          dense outlined v-once readonly
          label="وقت القيام"
          :class="textFieldStyle"
          :value="ticket.departureDate.toLocaleTimeString()" />
        <v-text-field
          dense outlined v-once readonly
          label="محطة الوصول"
          :class="textFieldStyle"
          :value="ticket.destination" />
        <v-text-field
          dense outlined v-once readonly
          label="السعر الإجمالي"
          :class="textFieldStyle"
          :value="ticket.totalFare" />
        <v-text-field
          dense outlined v-once readonly
          label="التليفون"
          :class="textFieldStyle"
          :value="ticket.phoneNumber" />
      </v-col>
    </v-row>
    <div class="d-flex mt-n3">
      <v-spacer />
      <div class="text--secondary">{{ ticket.pnr }}</div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
    textFieldStyle: 'my-n2'
  }
},
props: {
    ticket: {type: Object, required: true}
}
}
</script>
