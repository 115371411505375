import { initializeAuth } from '@xbus/auth'

function authorizeUser(user) {
  return user.role === "super" || user.role === "agent";
}
const auth = initializeAuth(authorizeUser, 58)

export default auth
export { errorCodeToArabMsg } from '@xbus/auth'

export function useAuth() {
  return auth
}